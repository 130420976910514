*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
}

body {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  outline: none;
  font-family: "Righteous", cursive;
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
}

.score {
  width: 16vw;
  position: absolute;
  right: 0;
  top: 5vw;
  z-index: 50;
  color: white;
  padding: 1vw;
  background: rgba(1, 0, 8, 0.6);
  box-shadow: 0 8px 32px 0 rgba(0, 4, 54, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 5px;
  font-size: 2vw;
  display: flex;
  align-items: center;
  /* text-align: center; */
}

.score span {
  display: block;
}

.score span:last-of-type {
  margin-inline-start: 10px;
  margin-top: 5px;
}

.high_score {
  width: 22vw;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 50;
  color: white;
  padding: 1vw;
  background: rgba(1, 0, 8, 0.6);
  box-shadow: 0 8px 32px 0 rgba(0, 4, 54, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 5px;
  font-size: 2vw;
  display: flex;
  align-items: center;
  /* text-align: center; */
}

.high_score span {
  display: block;
}

.high_score span:last-of-type {
  margin-inline-start: 10px;
  margin-top: 5px;
}

.speed {
  width: 22vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
  color: white;
  padding: 1vw;
  background: rgba(1, 0, 8, 0.6);
  box-shadow: 0 8px 32px 0 rgba(0, 4, 54, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 5px;
  font-size: 2vw;
  display: flex;
  align-items: center;
  /* text-align: center; */
}

.speed span {
  display: block;
}

.speed span:last-of-type {
  margin-inline-start: 10px;
  margin-top: 5px;
}

.start {
  width: 130px;
  height: 60px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 50;
  color: white;
  padding: 20px;
  background: rgba(1, 0, 8, 0.6);
  box-shadow: 0 8px 32px 0 rgba(0, 4, 54, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 5px;
  font-size: 30px;
  display: flex;
  align-items: center;
  /* text-align: center; */
}

.start span {
  display: block;
}

.start span:last-of-type {
  margin-inline-start: 10px;
  margin-top: 5px;
}

.game_over {
  width: 228px;
  height: 60px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 50;
  color: white;
  padding: 20px;
  background: rgba(1, 0, 8, 0.6);
  box-shadow: 0 8px 32px 0 rgba(0, 4, 54, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 5px;
  font-size: 30px;
  display: flex;
  align-items: center;
  /* text-align: center; */
}

.game_over span {
  display: block;
}

.game_over span:last-of-type {
  margin-inline-start: 10px;
  margin-top: 5px;
}

@media screen and (max-width: 500px) {
  .score {
    width: 150px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 50;
    color: white;
    padding: 10px;
    background: rgba(1, 0, 8, 0.6);
    box-shadow: 0 8px 32px 0 rgba(0, 4, 54, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 5px;
    font-size: 15px;
    display: flex;
    align-items: center;
    /* text-align: center; */
  }
  .speed {
    width: 150px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 50;
    color: white;
    padding: 10px;
    background: rgba(1, 0, 8, 0.6);
    box-shadow: 0 8px 32px 0 rgba(0, 4, 54, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 5px;
    font-size: 15px;
    display: flex;
    align-items: center;
    /* text-align: center; */
  }
  .start {
    width: 150px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 50;
    color: white;
    padding: 10px;
    background: rgba(1, 0, 8, 0.6);
    box-shadow: 0 8px 32px 0 rgba(0, 4, 54, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 5px;
    font-size: 15px;
    display: flex;
    align-items: center;
    text-align: center;
  }
}
